import { http, IAxiosResponse } from './http'

export function layout(params: { id?: number; type?: string }) {
  return http.get('/index/home', {
    params,
  })
}

export function companyInfo() {
  return http.get('/index/get_company_information')
}

//获取省市区
export function getRegion() {
  return http.get('/other/region')
}
//获取街道
export function getTowns(params: {
  province: string
  city: string
  area: string
}) {
  return http.get('/next_region', {
    params,
    loading: true,
    noAuth: true,
  })
}

export function getCode() {
  return http.get('/auth/get_login_qrcode')
}

export function check(params: any) {
  return http.get('/auth/check_qrcode_login', { params, loading: false })
}

export function codeLogin(params: any) {
  return http.get('/auth/qrcode_login', { params, loading: false })
}

/* 智能识别地址 */
export function getAddressanalysis(params: any) {
  return http.post('/shop/get_address_by_word', params, { loading: true })
}

/* 我的邀约码 */
export function inviteQRCode() {
  return http.get('/my/get_invite_qrcode', {
    loading: false,
  })
}

export function getProjects() {
  return http.get('/other/produce/projects')
}

export function getFeedBack(params: any) {
  return http.get('/feedback/list', { params })
}
// 提交意见反馈
export function doFeedback(params: any) {
  return http.post('/feedback/do_feedback', params)
}
//意见反馈详情
export function getFeedbackDetail(params: any) {
  return http.get('/feedback/detail', { params })
}

// 获取最新未读意见反馈回复
export function getNewFeedback() {
  return http.get('/feedback/new_feedback')
}
// 获取最新未读意见反馈回复
export function getFeedbackPhone() {
  return http.get('/feedback/get_phone')
}
// 获取业务配置
export function getSysConfig(params) {
  return http.get('/index/get_sys_config', {
    params,
    loading: false,
  })
}


//意见列表 分享查看
export function getFeedbackListApi(params: any) {
  return http.get('/feedback_list', { params })
}
//意见列表 分享查看
export function getFeedbackDetailApi(params: any) {
  return http.get('/feedback/detail', { params })
}



// h5我的回复接口
export function doReplyApi(params: any) {
  return http.post('/feedback/do_reply', params)
}


//  前台快捷回复配置列表
export function getFastReplyConfigApi(params: any) {
  return http.get('/feedback/get_fast_reply_config', { params })
}




//入库单列表
export function getInstockSample(params: any) {
  return http.get('/produce/supply_sample_instock/list', { params })
}

// 创建入库单
export function createInstockSample(params: any) {
  return http.post('/produce/supply_sample_instock/create', params)
}


//删除入库单
export function delInstockSample(params: any) {
  return http.get('/produce/supply_sample_instock/delete', { params })
}

//入库单详情
export function getInstockSampleDetail(params: any) {
  return http.get('/produce/supply_sample_instock/detail', { params })
}

// 入库单新增/删除 1：新增  2：删除
export function saveInstockDetail(params: any) {
  return http.post('/produce/supply_sample_instock/save_detail', params)
}
// 提交入库单
export function submitInstock(params: any) {
  return http.post('/produce/supply_sample_instock/submit', params)
}
//发货单列表
export function getSendSample(params: any) {
  return http.get('/produce/supply_sample_delivery/list', { params })
}
//可选业务线
export function getCanBusiness() {
  return http.get('/produce/supply_sample_delivery/get_can_business_type')
}


//需求方列表
export function getCustomerList(params: any) {
  return http.get('/produce/supply_sample_delivery/get_customer_list', { params })
}
// 新建发货单
export function createSendSample(params: any) {
  return http.post('/produce/supply_sample_delivery/create', params)
}
//删除发货单
export function delSendSample(params: any) {
  return http.post('/produce/supply_sample_delivery/delete', params)
}

//发货单详情列表
export function getSendSampleDetail(params: any) {
  return http.get('/produce/supply_sample_delivery/detail', { params })
}

//扫一扫/手动添加发货单明细
export function addSampleDelivery(params: any) {
  return http.post('/produce/supply_sample_delivery/scan', params)
}
//删除发货单
export function delSendSampleDetail(params: any) {
  return http.post('/produce/supply_sample_delivery/detail_delete', params)
}

// 提交发货单
export function submitSendSample(params: any) {
  return http.post('/produce/supply_sample_delivery/submit', params)
}
//获取物流信息
export function getSendFreight(params: any) {
  return http.get('/produce/supply_sample_delivery/get_freight_info', { params })
}
// 录入物流信息
export function addFreight(params: any) {
  return http.post('/produce/supply_sample_delivery/add_freight', params)
}
// 物流签收
export function signFreight(params: any) {
  return http.post('/produce/supply_sample_delivery/sign_in', params)
}
//获取样品信息
export function getSupplySampleList(params: any) {
  return http.get('/produce_supply_sample/supply_sample_list', { params })
}
//物流查询
export function getUserFreight(params: any) {
  return http.get('/order/get_user_freight', { params })
}


//补货请求
export function replenishment(params: {
  goods_id?: string | number,
  sku_id?: string | number,
  creator_shop_id?: string | number,
}) {
  return http.post('/feedback/do_goods_need_feedback', params)
}
// 基于入库创建发货单
export function createByInStock(params: any) {
  return http.post('/produce/supply_sample_delivery/create_by_in_stock', params)
}



//供应商报表
export function getSaleStk(params: any) {
  return http.get('/produce/my/get_sale_stk', { params })
}



//供应商报表 筛选
export function getSaleStkSelection(params: any) {
  return http.get('/produce/my/get_sale_stk_selection', { params })
}
//  点赞评论接口
export function userActionApi(params: any) {
  return http.post('/feedback/user_action', params)
}
// 获取评论列表
export function get_comments_logs(params: any) {
  return http.get('/get_comments_logs', { params })
}





// 设计中心评审
export function getDesignReviewList(params: any) {
  return http.get('/produce/design_review/list', { params })
}

// 设计中心评审筛选条件
export function getListScreen(params: any) {
  return http.get('/produce/design_review/design_selection', { params })
}

//设计评审详情列表
export function getDesignReviewDetail(params: any) {
  return http.get('/produce/design_review/design_detail', { params, loading: false })
}


//设计评审操作
export function setReviewDetailAction(params: any) {
  return http.post('/produce/design_review/do_action', params, { loading: false })
}


//设计评审操作
export function getDraftDetail(params: any) {
  return http.get('/produce/design_review/design_review_detail', { params })
}

// 评审统计 区域
export function getStatisticsAreas(params: any) {
  return http.get('/produce/design_review/review/statistics', { params })
}

// 评审统计排行榜
export function getStatisticsRanking(params: any) {
  return http.get('/produce/design_review/review/ranking', { params })
}
// 评审评语明细
export function getStatisticsComments(params: any) {
  return http.get('/produce/design_review/review/comments', { params })
}
// 是否可查看统计详情
export function getIsCanSeeStatistics(params: any) {
  return http.get('/produce/design_review/get_total_power', { params })
}






//返厂单列表
export function getReturnFactoryList(params: any) {
  return http.get('/produce/supply_sample_return_factory/list', { params })
}
// 新建返厂单
export function createReturnFactory(params: any) {
  return http.post('/produce/supply_sample_return_factory/create', params)
}
//删除返厂单
export function delReturnFactory(params: any) {
  return http.get('/produce/supply_sample_return_factory/delete', { params })
}
//返厂单详情
export function ReturnFactoryDetail(params: any) {
  return http.get('/produce/supply_sample_return_factory/detail', { params })
}
// 返厂单详情新增
export function addReturnFactory(params: any) {
  return http.post('/produce/supply_sample_return_factory/save_detail', params)
}
// 返厂单提交
export function submitReturnFactory(params: any) {
  return http.post('/produce/supply_sample_return_factory/submit', params)
}
// 获取历史地址
export function getHistoryAddress() {
  return http.get('/produce/supply_sample_delivery/get_history_address')
}
